var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-center",class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('div',{ref:"videoContainer",staticClass:"homepage-hero__video-container mx-5"},[_c('video',{ref:"video",staticClass:"homepage-hero__video",attrs:{"id":"heroVideo","playsinline":"","muted":"","loop":"","preload":"auto","poster":_vm.block?.video?.thumbnail?.url || ''},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.block?.video?.link,"type":"video/mp4"}}),_vm._v("\n      Your browser does not support the video tag.\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }