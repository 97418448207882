
import Vue, { PropType } from 'vue'
import { Block } from '@interfaces/BlockTypes'

export default Vue.extend({
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
  data() {
    return {
      observer: null as IntersectionObserver | null,
      hasStartedPlaying: false,
    }
  },
  mounted() {
    this.setupIntersectionObserver()
  },
  beforeDestroy() {
    this.destroyIntersectionObserver()
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.hasStartedPlaying) {
            const video = this.$refs.video as HTMLVideoElement
            if (video) {
              video.play()
              this.hasStartedPlaying = true
              this.destroyIntersectionObserver()
            }
          }
        })
      }, options)

      const videoContainer = this.$refs.videoContainer as Element
      if (videoContainer) {
        this.observer.observe(videoContainer)
      }
    },
    destroyIntersectionObserver() {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
  },
})
